/**
 * Gescots API
 * API pour GesCoTS - TSN
 *
 * The version of the OpenAPI document: 1.00
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EnumRoleUtilisateur =
  'GESTION_UTILISATEUR'
  | 'CONSULTATION_ANNUAIRE'
  | 'MODIFICATION_ANNUAIRE'
  | 'CONSULTATION_PARAM'
  | 'MODIFICATION_PARAM'
  | 'CONSULTATION_DEVIS'
  | 'MODIFICATION_DEVIS'
  | 'CONSULTATION_DOSSIER'
  | 'MODIFICATION_DOSSIER'
  | 'COMPTA'
  | 'PROSPECT'
  | 'CLIENT'
  | 'AGENT'
  | 'FINANCIER';

export const EnumRoleUtilisateur = {
  GestionUtilisateur: 'GESTION_UTILISATEUR' as EnumRoleUtilisateur,
  ConsultationAnnuaire: 'CONSULTATION_ANNUAIRE' as EnumRoleUtilisateur,
  ModificationAnnuaire: 'MODIFICATION_ANNUAIRE' as EnumRoleUtilisateur,
  ConsultationParam: 'CONSULTATION_PARAM' as EnumRoleUtilisateur,
  ModificationParam: 'MODIFICATION_PARAM' as EnumRoleUtilisateur,
  ConsultationDevis: 'CONSULTATION_DEVIS' as EnumRoleUtilisateur,
  ModificationDevis: 'MODIFICATION_DEVIS' as EnumRoleUtilisateur,
  ConsultationDossier: 'CONSULTATION_DOSSIER' as EnumRoleUtilisateur,
  ModificationDossier: 'MODIFICATION_DOSSIER' as EnumRoleUtilisateur,
  Compta: 'COMPTA' as EnumRoleUtilisateur,
  Prospect: 'PROSPECT' as EnumRoleUtilisateur,
  Client: 'CLIENT' as EnumRoleUtilisateur,
  Agent: 'AGENT' as EnumRoleUtilisateur,
  Financier: 'FINANCIER' as EnumRoleUtilisateur,
};

export const allTSN = [

  EnumRoleUtilisateur.GestionUtilisateur,
  EnumRoleUtilisateur.ConsultationAnnuaire,
  EnumRoleUtilisateur.ModificationAnnuaire,
  EnumRoleUtilisateur.ConsultationParam,
  EnumRoleUtilisateur.ModificationParam,
  EnumRoleUtilisateur.ConsultationDevis,
  EnumRoleUtilisateur.ModificationDevis,
  EnumRoleUtilisateur.ConsultationDossier,
  EnumRoleUtilisateur.ModificationDossier,
  EnumRoleUtilisateur.Compta,
];

