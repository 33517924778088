import { Routes } from '@angular/router';
import { Url } from '../../classes/url';

//Route for content layout with sidebar, navbar and footer.

export const PRIVATE_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('../../../modules/home/home.module').then((m) => m.HomeModule),
    title: 'GesCots',
  },
  {
    path: Url.USERS_BASE,
    loadChildren: () => import('../../../modules/utilisateurs/utilisateurs.module').then((m) => m.UtilisateursModule),
    title: 'Utilisateurs',
  },
  {
    path: Url.CONTACTS_BASE,
    loadChildren: () => import('../../../modules/contacts/contacts.module').then((m) => m.ContactsModule),
    title: 'Contacts',
  },
  {
    path: Url.PARAMS_BASE,
    loadChildren: () => import('../../../modules/params/params.module').then((m) => m.ParamsModule),
    title: 'Param�tres',
  },
  {
    path: Url.COMMERCIAL,
    loadChildren: () => import('../../../modules/dossiers/dossiers.module').then((m) => m.DossiersModule),
    title: 'Dossiers',
  },
  {
    path: Url.COMPTA_BASE,
    loadChildren: () => import('../../../modules/compta/compta.module').then((m) => m.ComptaModule),
    title: 'Comptabilit�',
  },
  {
    path: Url.ESPACE_CLIENT_BASE,
    loadChildren: () => import('../../../modules/acces-client/acces-client.module').then((m) => m.AccesClientModule),
    title: 'Acc�s Client',
  },
  {
    path: Url.EVENEMENT_BASE,
    loadChildren: () => import('../../../modules/notifications/notifications.module').then((m) => m.NotificationsModule),
    title: 'Notification',
  },
  {
    path: Url.ESPACE_AGENT_BASE,
    loadChildren: () => import('../../../modules/acces-agent/acces-agent.module').then((m) => m.AccesAgentModule),
    title: 'Acc�s Agent',
  },
];
