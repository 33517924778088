<!-- START Notification Sidebar-->
<aside class="notification-sidebar d-none d-sm-none d-md-block" id="notification-sidebar" [ngClass]="{open: isOpen}">
    <a class="notification-sidebar-close" (click)="onClose()">
        <i class="ft-x font-medium-3 grey darken-1"></i>
    </a>
    <div class="side-nav notification-sidebar-content" [perfectScrollbar]>
        <div class="row">
            <div class="col-12 notification-tab-content">
                <nav ngbNav #nav="ngbNav" class="nav-tabs">
                    <ng-container ngbNavItem="roles" class="nav-item">
                        <a ngbNavLink class="nav-link">R�les</a>
                        <ng-template ngbNavContent>
                            <div class="row tab-pane" id="roles-tab">
                                <div class="col-12">
                                    <h5 class="my-2 text-bold-500"><i class="fa-solid fa-user-tag"></i> R�les de l'utilisateur</h5>
                                    <ul>
                                        <li *ngFor="let role of userRoles">{{role}}</li>
                                    </ul>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </nav>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
    </div>
</aside>
<!-- END Notification Sidebar-->
