import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ErreurService } from '../erreur.service';
import { BaseService } from '../base.service';
import { Pageable } from '../../classes/pageable';
import { Observable, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TemplateApiService } from '../../../api/api/params/templateApi.service';
import { EvenementsApiService } from '../../../api/api/notification/evenementsApi.service';
import { EvenementPaged } from '../../../api/model/notification/evenementPaged';
import { RechercheEvenement } from '../../../api/model/notification/rechercheEvenement';
import { EnumTypeEvenement } from '../../../api/model/notification/enumTypeEvenement';
import { Router } from '@angular/router';
import { Url } from '../../classes/url';

export interface IEvenementService {
  /**
   * recherche de devis
   * @param page pagination
   * @param recherche filtre de recherche
   */
  recherche(page: Pageable, recherche: RechercheEvenement): Observable<EvenementPaged>;

  /**
   * Traite un �v�nement
   * @param id id de l'�v�nement a marquer trait�
   */
  traiteEvenement(id: number): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class EvenementService extends BaseService implements IEvenementService {
  private readonly _idNonPresent: string = 'ID non pr�sent';
  private readonly _idPresent: string = 'ID pr�sent';

  constructor(
    protected readonly service: EvenementsApiService,
    protected override readonly logger: NGXLogger,
    protected readonly erreurService: ErreurService,
    protected readonly templateService: TemplateApiService,
    protected readonly router: Router,
  ) {
    super(logger);
  }

  recherche(page: Pageable, recherche: RechercheEvenement): Observable<EvenementPaged> {
    return this.service.listeEvenements(page.page, page.size, recherche, page.getSort()).pipe(
      tap(this.logResult('Recherche d\'�venements')),
      catchError((err) => this.erreurService.handleError(err)),
    );
  }

  traiteEvenement(id: number): Observable<void> {
    return this.service.traiteEvenement(id).pipe(
      tap(this.logResult('MArque un �v�nement comme trait�')),
      catchError((err) => this.erreurService.handleError(err)),
    );
  }

  redirectToObjectID(data: { type: EnumTypeEvenement; idObject: number; }, redirect: 'consultation' | 'modification'): void {
    switch (data.type) {
      case 'DEMANDE_DEVIS':
        redirect === 'consultation' ?
          this.router.navigate([Url.DEMANDE_DEVIS_TSN_PAGE, data.idObject]) :
          this.router.navigate([Url.CONVERSION_DEMANDE_DEVIS_TSN, data.idObject]);

        break;
      case 'ACCEPTE_DEVIS':
        redirect === 'consultation' ?
          this.router.navigate([Url.DEVIS_PAGE, data.idObject]) :
          this.router.navigate([Url.DEVIS_PAGE, data.idObject, Url.MODIFICATION]);
        break;
      case 'AJOUT_PJ_DOSSIER':
        redirect === 'consultation' ?
          this.router.navigate([Url.DOSSIER_PAGE, data.idObject], { fragment: 'piecesJointes' }) :
          this.router.navigate([Url.DOSSIER_PAGE, data.idObject, Url.MODIFICATION], { fragment: 'piecesJointes' });
        break;
      case 'UPDATE_GROUPAGE':
        redirect === 'consultation' ?
          this.router.navigate([Url.DOSSIER_PAGE, data.idObject], { fragment: 'fret' }) :
          this.router.navigate([Url.DOSSIER_PAGE, data.idObject, Url.MODIFICATION], { fragment: 'fret' });

        break;
      case 'AJOUT_PJ_GROUPAGE':
        redirect === 'consultation' ?
          this.router.navigate([Url.DOSSIER_PAGE, data.idObject], { fragment: 'fret' }) :
          this.router.navigate([Url.DOSSIER_PAGE, data.idObject, Url.MODIFICATION], { fragment: 'fret' });

        break;
      case 'UPDATE_DOSSIER' :
      case 'ASSOCIE_DOSSIER':
        redirect === 'consultation' ?
          this.router.navigate([Url.DOSSIER_PAGE, data.idObject]) :
          this.router.navigate([Url.DOSSIER_PAGE, data.idObject, Url.MODIFICATION]);
        break;

      case 'DEMANDE_DOSSIER':
        this.router.navigate([Url.DEMANDE_DOSSIER_TO_DOSSIER_PAGE, data.idObject]);
        break;
      case 'NOUVEAU_COMPTE_CLIENT':
        this.router.navigate([Url.PROSPECTS_LISTE]);
        break;
      default:
        return;
    }
  }
}
