<nav [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}"
     class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div (click)="toggleSidebar()"
           class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center" data-toggle="collapse">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a (click)="ToggleClass()" appToggleFullscreen class="nav-link apptogglefullscreen" href="javascript:"
             id="navbar-fullscreen"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a *ngIf="config?.layout.menuPosition === 'Top'" [routerLink]="['/']" class="logo-text">
                <div class="logo-img"><img [src]="logoUrl" alt="GesCoTS logo" class="logo-img"></div>
                <span
                  class="text">GesCoTs</span>
              </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="isLoggedIn" class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">

          <li class="dropdown nav-item mr-1" display="static" ngbDropdown placement="bottom-left">
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-center" data-toggle="dropdown"
               href="javascript:" id="dropdownBasic2" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{userProfile?.firstName + " " + userProfile?.lastName}}</span>
              </div>
            </a>
            <div aria-labelledby="dropdownBasic2" class="dropdown-menu text-left dropdown-menu-right m-0 pb-0"
                 ngbDropdownMenu>
              <a (click)="logOut()" class="dropdown-item">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>D�connexion</span>
                </div>
              </a>
            </div>
          </li>
          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a (click)="toggleNotificationSidebar();" class="nav-link notification-sidebar-toggle"><i
              class="ft-align-right font-medium-3"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>


</nav>
