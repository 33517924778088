import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/param/user.service';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss'],
})
export class NotificationSidebarComponent implements OnInit, OnDestroy {
  /** R�les de l'utilisateur.*/
  userRoles: string[];
  /** souscription. */
  layoutSub: Subscription;
  /** Ouvert ou ferm�. */
  isOpen = false;

  constructor(
    private readonly layoutService: LayoutService,
    private readonly userService: UserService
  ) {}

  ngOnDestroy() {
    this.layoutSub.unsubscribe();
  }

  onClose() {
    this.layoutService.toggleNotificationSidebar(false);
  }

  ngOnInit(): void {
    this.layoutSub = this.layoutService.toggleNotiSidebar$.subscribe({
      next: (open) => (this.isOpen = open),
    });

    this.userService.isLoggedIn().then((value) => {
      if (value) {
        this.userRoles = this.userService
          .getUserRoles()
          .filter((value) => value === value.toUpperCase())
          .sort();
      }
    });
  }
}
