import { RouteInfo } from './vertical-menu.metadata';
import { Url } from '../../classes/url';
import { allTSN, EnumRoleUtilisateur } from '../../../api/model/utilisateurs/enumRoleUtilisateur';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: `${Url.HOME_BASE}`,
    title: 'Page d\'accueil',
    icon: 'fa fa-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: allTSN.concat(EnumRoleUtilisateur.Agent, EnumRoleUtilisateur.Client),
  }, {
    path: `${Url.EVENEMENT_BASE}`,
    title: '�v�nements',
    icon: 'fa-solid fa-exclamation',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: allTSN,
    submenu: [],
  },
  {
    path: '',
    title: 'Commercial',
    icon: 'fa-solid fa-handshake',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: [
      EnumRoleUtilisateur.ConsultationDevis,
      EnumRoleUtilisateur.ModificationDevis,
      EnumRoleUtilisateur.ConsultationDossier,
      EnumRoleUtilisateur.ModificationDossier,
    ],
    submenu: [
      {
        path: Url.DEMANDE_DEVIS_TSN_PAGE,
        title: 'Demandes de devis',
        icon: 'fa fa-person-circle-exclamation submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        roles: [EnumRoleUtilisateur.ConsultationDevis, EnumRoleUtilisateur.ModificationDevis],
        submenu: [],
      },
      {
        path: `${Url.LISTE_DEMANDE_DOSSIER_PAGE}`,
        title: 'Demandes de dossiers',
        icon: 'fa fa-folder-plus  submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        roles: [EnumRoleUtilisateur.ModificationDossier],
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.DEVIS_PAGE,
        title: 'Devis',
        icon: 'fa fa-file-circle-question submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        roles: [EnumRoleUtilisateur.ConsultationDevis, EnumRoleUtilisateur.ModificationDevis],
        submenu: [],
      },
      {
        path: Url.DOSSIER_PAGE,
        title: 'Dossiers',
        icon: 'fa fa-folder submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        roles: [EnumRoleUtilisateur.ConsultationDossier, EnumRoleUtilisateur.ModificationDossier],
        isExternalLink: false,
        submenu: [],
      },
    ],
  },


  {
    path: '',
    title: 'Annuaire',
    icon: 'fa-solid fa-address-book',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: [EnumRoleUtilisateur.ConsultationAnnuaire, EnumRoleUtilisateur.ModificationAnnuaire],
    submenu: [
      {
        path: Url.CLIENT_PAGE,
        title: 'Clients',
        icon: 'fa fa-users-viewfinder submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.DECLARANT_PAGE,
        title: 'D�clarants ext',
        icon: 'fa fa-user-pen submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.OPERATEUR_PAGE,
        title: 'Op�rateur',
        icon: 'fa fa-user-pen submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.AGENT_PAGE,
        title: 'Agents ext',
        icon: 'fa fa-user-tie submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.TRANSPORTEUR_PAGE,
        title: 'Transporteurs ext',
        icon: 'fa fa-truck-ramp-box submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },

  {
    path: '',
    title: 'Comptabilit�',
    icon: 'fa fa-money-bill-transfer',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: [EnumRoleUtilisateur.Compta],
    submenu: [
      {
        path: `${Url.EXPORT_FACTURE_PAGE}`,
        title: 'Export de facture',
        icon: 'fa fa-file-invoice-dollar submenu-icon',
        class: '',
        roles: [EnumRoleUtilisateur.Compta,
        ],
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: `${Url.LISTING_ECRITURES_PAGE}`,
        title: 'Listing des �critures',
        icon: 'fa fa-list-check submenu-icon',
        class: '',
        roles: [EnumRoleUtilisateur.Compta,
        ],
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }, {
        path: Url.RAPPORT_VALEUR_CAF_PAGE,
        title: 'Rapport client val. CAF',
        icon: 'fa fa-magnifying-glass-dollar submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        roles: [EnumRoleUtilisateur.Compta],
        isExternalLink: false,
        submenu: [],
      }, {
        path: Url.RECAP_ACTIVITE_PAGE,
        title: 'Rapport d\'activit�',
        icon: 'fa fa-chart-line submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        roles: [EnumRoleUtilisateur.Compta],
        isExternalLink: false,
        submenu: [],
      }],
  }, {
    path: '',
    title: 'Statistiques',
    icon: 'fa-solid fa-chart-simple',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: [
      EnumRoleUtilisateur.ConsultationDossier,
      EnumRoleUtilisateur.ModificationDossier,
      EnumRoleUtilisateur.Client,
      EnumRoleUtilisateur.Financier,
    ],
    submenu: [

      {
        path: Url.MARGES_PAGE,
        title: 'Tableau des marges',
        icon: 'fa fa-money-bills submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        roles: [EnumRoleUtilisateur.ConsultationDossier, EnumRoleUtilisateur.ModificationDossier],
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.RAPPORT_ASSURANCE_PAGE,
        title: 'Rapport des assurances',
        icon: 'fa fa-dolly submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        roles: [EnumRoleUtilisateur.ConsultationDossier, EnumRoleUtilisateur.ModificationDossier],
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.RAPPORT_VOLUMETRIE_PAGE,
        title: 'Rapport de volum�trie',
        icon: 'fa fa-chart-simple submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        roles: [EnumRoleUtilisateur.ConsultationDossier, EnumRoleUtilisateur.ModificationDossier, EnumRoleUtilisateur.Client, EnumRoleUtilisateur.Financier],
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.RAPPORT_OPERATEUR_PAGE,
        title: 'Statistiques des op�rateurs',
        icon: 'fa fa-chart-line submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        roles: [EnumRoleUtilisateur.ConsultationDossier, EnumRoleUtilisateur.ModificationDossier],
        isExternalLink: false,
        submenu: [],
      },

    ],

  },
  {
    path: '',
    title: 'Utilisateurs',
    icon: 'fa-solid fa-user-group',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: [EnumRoleUtilisateur.GestionUtilisateur],
    submenu: [
      {
        path: Url.UTILISATEURS_PAGE,
        title: 'Utilisateurs',
        icon: 'fa fa-user-circle submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.PROFILS_PAGE,
        title: 'Profils',
        icon: 'fa fa-id-badge submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.PROSPECTS_LISTE,
        title: 'Prospects',
        icon: 'fa fa-user-plus submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Param�tres',
    icon: 'fa-solid fa-gears',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: [EnumRoleUtilisateur.ConsultationParam, EnumRoleUtilisateur.ModificationParam],
    submenu: [
      {
        path: Url.TVA_PAGE,
        title: 'Tva',
        icon: 'fa fa-landmark submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.LIGNE_PAGE,
        title: 'Rubriques de facturation',
        icon: 'fa fa-receipt submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.LIEU_PAGE,
        title: 'Lieux locaux',
        icon: 'fa fa-location-dot submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.CONSTANTES_PAGE,
        title: 'Constantes de facturation',
        icon: 'fa fa-calculator submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: Url.GRILLE_PAGE,
        title: 'Grille de livraison locale',
        icon: 'fa fa-truck submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },

  {
    path: `${Url.DEMANDE_DEVIS_PAGE}`,
    title: 'Demandes de devis',
    icon: 'fa fa-question',
    class: '',
    badge: '',
    badgeClass: '',
    roles: [EnumRoleUtilisateur.Prospect, EnumRoleUtilisateur.Client],
    isExternalLink: false,
    submenu: [],
  },

  {
    path: Url.DEVIS_CLIENT_PAGE,
    title: 'Devis',
    icon: 'fa fa-file-circle-question',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: [EnumRoleUtilisateur.Client],
    submenu: [],
  },
  {
    path: `${Url.DOSSIER_CLIENT_PAGE}`,
    title: 'Suivi des commandes',
    icon: 'fa fa-folder',
    class: '',
    badge: '',
    badgeClass: '',
    roles: [EnumRoleUtilisateur.Client],
    isExternalLink: false,
    submenu: [],
  },

  {
    path: `${Url.LISTE_FRET_PAGE}`,
    title: 'Frets',
    icon: 'fa fa-ship',
    class: '',
    badge: '',
    badgeClass: '',
    roles: [EnumRoleUtilisateur.Agent],
    isExternalLink: false,
    submenu: [],
  },
  {
    path: `${Url.DOSSIER_AGENT_PAGE}`,
    title: 'Dossiers',
    icon: 'fa fa-folder',
    class: '',
    badge: '',
    badgeClass: '',
    roles: [EnumRoleUtilisateur.Agent],
    isExternalLink: false,
    submenu: [],
  },
  {
    path: `${Url.DEMANDE_DOSSIER_PAGE}`,
    title: 'Demande de dossier',
    icon: 'fa fa-folder-plus',
    class: '',
    badge: '',
    badgeClass: '',
    roles: [EnumRoleUtilisateur.Agent],
    isExternalLink: false,
    submenu: [],
  },
];
