import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { CoreModule } from '../core.module';
import { UserService } from '../services/param/user.service';
import { NGXLogger } from 'ngx-logger';
import { Url } from '../classes/url';

@Injectable({
  providedIn: CoreModule,
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    private readonly logger: NGXLogger,
    protected readonly keycloak: KeycloakService,
    private readonly userService: UserService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    this.logger.debug('Auth guard');

    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      this.logger.debug('Pas authentifi�');
      this.userService.resetProfil();
      await this.userService.login();
      return false;
    }
    this.logger.debug('Authentifi�');
    await this.userService.loadUserProfile();
    return true;
  }
}
