<section id="error">
  <div class="container-fluid">
    <div class="row auth-height full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="row">
          <div class="col-12 text-center">
            <img alt="" class="img-fluid error-img mt-2" height="300" src="assets/img/gallery/error.png" width="400">
            <h1 class="mt-4">Une erreur est survenue</h1>
            <div class="w-75 error-text mx-auto mt-4">
              <p>{{erreur}}</p>
            </div>
            <a [routerLink]="['/']" class="btn btn-warning my-2"><i aria-hidden="true" class="fa fa-home"></i> Page d'accueil</a>
            <button  class="ml-2 btn btn-danger my-2"><i class="fa-solid fa-right-from-bracket"></i> Se
              d�connecter
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

