import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment.local-dev';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

  /** classe � appliquer au texte */
  @Input() textClass: string;

  environnement: string = environment.version;

  /**
   * Le num�ro de version
   *
   * @returns le num�ro de version
   */
  version(): string {
    let versions = environment.version.split('-');
    if (versions.length === 2) {
      return `${versions[0]} (dev ${versions[1]})`;
    }

    versions = environment.version.split('.');
    if (versions.length === 3) {
      if (versions[2] === '0') {
        return `${versions[0]}.${versions[1]}`;
      } else {
        return `${versions[0]}.${versions[1]} (alpha ${versions[2]})`;
      }
    }

    return environment.version;
  }

}
