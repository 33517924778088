import {Component, ElementRef, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {LayoutService} from '../services/layout.service';

@Component({
  selector: 'app-off-canvas',
  templateUrl: './off-canvas.component.html',
  styleUrls: ['./off-canvas.component.scss']
})
export class OffCanvasComponent implements OnInit, OnDestroy {
  @ViewChild('offcanvas') customizer: ElementRef;
  /** Ouvert ? */
  isOpen = false;
  /** souscription. */
  layoutSub: Subscription;
  /** Template � afficher. */
  currentTemplate: TemplateRef<any>;

  constructor(
    private readonly renderer: Renderer2,
    private readonly layoutService: LayoutService
  ) {
  }

  ngOnInit(): void {
    this.layoutSub = this.layoutService.toggleOffCanvas$.subscribe({
      next: (template) => {
        if (template) {
          this.currentTemplate = template;
          this.isOpen = true;
        } else {
          this.isOpen = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.layoutSub.unsubscribe();
  }

  closeCustomizer() {
    this.renderer.removeClass(this.customizer.nativeElement, 'open');
    this.isOpen = false;
  }
}
