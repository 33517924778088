import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Url } from '../../classes/url';

/**
 * classe g�n�rique pour les composants
 */
@Component({
  selector: 'app-base-component',
  template: '<div></div>',
})
export class BaseComponent implements OnDestroy {
  /** un submit est en cours */
  isSubmit = false;
  /** message d'erreur survenue */
  erreur?: string;
  /** urls de l'appli */
  url = Url;
  /** les subscriptions de la page (pour les observables non HTTP notamment) */
  subscription: Subscription = new Subscription();
  /** Nb chargement (par d�faut 1, la page se charge!). */
  private nbLoading = 1;

  /**
   * @returns si le composant est en cours de chargement. Il faut que la somme des chargements/d�chargements soit � 0 pour que le loading soit
   * termin�
   */
  get isLoading(): boolean {
    return this.nbLoading !== 0;
  }

  /**
   * @deprecated utiliser showLoading et HideLoading.
   * @param value true = on charge, false= on d�charge.
   */
  set isLoading(value: boolean) {
    if (value) {
      this.showLoading();
    } else {
      this.hideLoading();
    }
  }

  /**
   * Charge le loader.
   */
  showLoading() {
    this.nbLoading++;
  }

  /**
   * D�sacherge le loader.
   */
  hideLoading() {
    if (this.nbLoading > 0) {
      this.nbLoading--;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Gestion des erreurs.
   * @param erreur erreur se produisant
   */
  handleErreur(erreur: any): void {
    this.erreur = this.erreur = erreur?.message ?? erreur;
    this.nbLoading = 0; //on arr�te tout chargement et on affiche une erreur
    this.isSubmit = false;
  }

  /**
   * G�re le passage d'onglet en onglets selon les onglets affich�s
   * @param nomOngletActuel - le nom de l'onglet actuel
   * @param configuration - la configuration
   * @param changeOnglet - la fonction appel�e pour changer d'onglet
   */
  protected ongletSuivantGenerique(nomOngletActuel: string, configuration: string[], changeOnglet: (e: string) => any): void {
    const index = configuration.indexOf(nomOngletActuel);
    if (index !== -1) {
      for (let i = index + 1; i < configuration.length; i++) {
        if (document.getElementById(configuration[i])) {
          changeOnglet(configuration[i]);
          return;
        }
      }
    }
  }
}
